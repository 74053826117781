import { useSessionStorage } from '@vueuse/core';

import type { Location } from '~/types';

export function useSelectedLocation() {
  const storage = useSessionStorage<Location>('selectedLocation', {} as any);

  return {
    locationId: computed(() => storage.value?.id),
    location: storage as unknown as Ref<Location>,
    setSelectedLocation: (location: Location) => (storage.value = location),
  };
}
